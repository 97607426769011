<template>
  <Layout>
    <PageHeader :items="items"/>
    <div class="row">
      <div class="col-md-12">
        <div class="card" style="margin-top:-30px">
          <div class="card-body">
            <div class="inner mb-2 row">
              <!-- For owner and branh login -->
              <div v-if="this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5" class="col-md-2"
                   style="width:16%">

                <label>Select Country </label>
                <multiselect v-model="country" :options="countryArr" :show-labels="false" label="country"
                             track-by="country" @input="getCorporateCities();"></multiselect>
              </div>
              <div v-if="this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5" class="col-md-2"
                   style="width:16%">

                <label>Select City </label>
                <multiselect v-model="city" :options="cityArr" :show-labels="false" label="city"
                             track-by="city" @input="getCorporateBranchByCorpID();"></multiselect>
              </div>

              <div v-if="this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5" class="col-md-3"
                   >
                <label>Select Corporate Branch </label>
                <multiselect v-model="branchID" :options="branches" :show-labels="false" label="corpBranchName"
                             track-by="corpBranchID" @input="onchangeBranch();"></multiselect>
              </div>
              <!--- PRINCIPLE --->
              <!-- Brand List-->
              <div
                  v-if="this.$storageData.login_type == 4 || this.$storageData.profile.empTypeID == 7 || this.$storageData.profile.empTypeID == 18 "
                  class="col-md-2"
                  style="width:16%">
                <label>Select Country </label>
                <multiselect v-model="country" :options="countryArr" :show-labels="false" label="country"
                             track-by="country" @input="getPrincipleCities();"></multiselect>
              </div>
              <!-- Brand List END-->
              <!-- Branch List-->
              <div v-if="this.$storageData.login_type == 4 || this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18
         " class="col-md-2"
                   style="width:16%">
                <label>Select City </label>
                <multiselect v-model="city" :options="cityArr" :show-labels="false" label="city"
                             track-by="city" @input="getPrincipleBranchByPrincipleID();"></multiselect>
              </div>
              <!-- Branch List END-->
              <!-- Branch List-->
              <div
                  v-if="this.$storageData.login_type == 4 || this.$storageData.profile.empTypeID == 7 || this.$storageData.profile.empTypeID == 18 "
                  class="col-md-2" style="width:20%">
                <label>Select Principal Branch </label>
                <multiselect v-model="branchID" :options="princBranches" :show-labels="false"
                             label="principleBranchName"
                             track-by="principleBranchID"></multiselect>
              </div>
              <!-- Branch List END-->
              <!-- Date Range-->
              <!-- Branch List END-->

              <!-- Apply filter -->
              <div class="col-md-2" style="width:auto; margin-top:22px;">
                <button class="btn btn-themeOrange" v-on:click="applyFilter()"> Apply Filter</button>
              </div>
              <!-- Apply filter END-->

              <!-- Clear filter -->
              <div class="col-md-2" style="width:16%;margin-top:22px;">
                <button class="btn btn-themeBrown" v-on:click="clearFilter()"> Clear Filter</button>
              </div>
              <!-- Clear filter END-->
            </div>
          </div>
        </div>
      </div>
      <!--FILTER END------>

      <div class="col-md-12" style="margin-top:30px;">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                <div class="btn-group" role="group">
                  <!-- <button type="button" class="btn btn-themeBrown">Excel</button>
                   <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select v-model="perPage"
                                   :options="pageOptions"
                                   size="sm"
                                   style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        class="form-control form-control-sm ms-2"
                        placeholder="Search..."
                        type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">

              <b-table
                  :current-page="currentPage" :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="tableData"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  bordered
                  hover
                  outlined
                  responsive
                  striped
                  @filtered="onFiltered"


              >


                <template v-slot:cell(Action)="data">
                  <router-link :to="{ name: 'complianceReport', params: { id: data.item.restBranchID } }">
                    <button class="btn btn-themeYellow" style="padding: 1px 6px;"> View</button>
                  </router-link>
                </template>


              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>

<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Compliance Report List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Compliance Report List",
      items: [
        {
          text: "Compliance Report List",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      restDetails: [],
      documentsArr: [],
      branches: [],
      branchID: "",
      tableData: [],
      principleBranchID: "",
      countryArr: [],
      country: "",
      cityArr: [],
      city: "",
      princBranches: [],
      corpBranchID: "",
      principleID: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",

          sortable: false,
        },
        {
          key: "restaurantName",
          sortable: true,
        },
        {
          key: "address",
          sortable: true,
        },
      ],

    };
  },

  validations: {},
  methods: {

    getCorporateRestaurants() {
      this.axios
          .post(this.$loggedRole+
              "/getCorporateRestaurants", {
                corpBranchID: this.corpBranchID,

              }
          )
          .then((response) => {
            this.tableData = response.data.data;


          });
    },
    getCorporateCountry() {
      this.axios
          .post(this.$loggedRole+
              "/getCorporateCountry", {corporateID: this.$storageData.profile.corporateID}
          )
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = (this.countryArr) ? this.countryArr[0] : "";
            this.getCorporateCities();

          });
    },

    getCorporateCities() {
      this.axios
          .post(this.$loggedRole+
              "/getCorporateCities", {
                corporateID: this.$storageData.profile.corporateID,
            country: (this.country) ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            corpBranchID:this.$storageData.profile.empTypeID  ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = (this.cityArr) ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();

          });
    },

    getCorporateBranchByCorpID() {
      this.axios
          .post(this.$loggedRole+
              "/getCorporateBranchByCorpID", {
                corporateID: this.$storageData.profile.corporateID,
            city: (this.city) ? this.city.city : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            corpBranchID:this.$storageData.profile.corpBranchID ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.branches = response.data.data;
            this.branchID = this.branches[0];
            this.corpBranchID = this.branchID.corpBranchID;
            this.getCorporateRestaurants();

          });
    },
    onchangeBranch() {
      if (this.$storageData.login_type == 4) {
        this.principleBranchID = this.branchID.principleBranchID;
        // this.getPrincipleRestaurants();
      } else {
        this.corpBranchID = this.branchID.corpBranchID;
        // this.getCorporateRestaurants();
      }


    },
    branchLogin() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.getCorporateRestaurants();
    },
    //////Principle login methods ///////////
    principleBranchLogin() {
      this.principleBranchID = this.$storageData.profile.principleBranchID;
      this.getPrincipleRestaurants();
    },

    getPrincipleRestaurants() {
      var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
      var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID : 0;

      this.axios
          .post(this.$loggedRole+
              "/getPrincipleBranchRestaurants", {
                'principleID': principleID,
                'principleBranchID': principleBranchID,
                // principleBranchID : (this.principleBranchID) ? this.principleBranchID :0,
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.pid,
                city: (this.city) ? this.city.city : "",
                country: (this.country) ? this.country.country : "",
              }
          )
          .then((response) => {
            this.tableData = response.data.data;
          });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getPrincipleCountry() {
      this.axios
          .post(this.$loggedRole+
              "/getPrincipleCountry", {
                principleID: this.$storageData.profile.principleID,
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.pid,
              }
          )
          .then((response) => {
            this.countryArr = response.data.data;
            // this.country = (this.countryArr) ? this.countryArr[0] : "";
            // this.getPrincipleCities();

          });
    },

    getPrincipleCities() {
      this.axios
          .post(this.$loggedRole+
              "/getPrincipleCities", {
                principleID: this.$storageData.profile.principleID,
                country: (this.country) ? this.country.country : "",
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.pid,
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            // this.city = (this.cityArr) ? this.cityArr[0] : "";
            //  this.getPrincipleBranchByPrincipleID();

          });
    },
    getPrincipleBranchByPrincipleID() {
      this.axios
          .post(this.$loggedRole+
              "/getPrincipleBranchByPrincipleID", {
                principleID: this.$storageData.profile.principleID,
                city: (this.city) ? this.city.city : "",
                country: (this.country) ? this.country.country : "",
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.pid,
              }
          )
          .then((response) => {
            this.princBranches = response.data.data;
            // this.branchID = this.princBranches[0];
            this.principleBranchID = this.branchID.principleBranchID;
            // this.getPrincipleRestaurants();


          });
    },
    brandLogin() {

      this.getCorporateCountry();

    },

    applyFilter() {
      if (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) {
        this.getCorporateRestaurants();
      } else {
        this.principleBranchID = (this.$storageData.login_type == 4 || this.$storageData.profile.empTypeID == 7 || this.$storageData.profile.empTypeID == 18) ? this.branchID.principleBranchID : this.$storageData.profile.principleBranchID;
        this.getPrincipleRestaurants();
      }
    },
    clearFilter() {
      this.typeID = 0;
      this.country = "";
      this.city = "";
      this.branchID = "";
      this.dateFilter = [];
      this.principleBranchID = (this.$storageData.login_type == 4 || this.$storageData.profile.empTypeID == 7 || this.$storageData.profile.empTypeID == 18) ? this.branchID.principleBranchID : this.$storageData.profile.principleBranchID;
      this.getPrincipleRestaurants();
    }
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    if (this.$storageData.login_type == 3) {
      this.getCorporateCountry();
       //(this.$storageData.profile.empTypeID == 6) ? this.branchLogin() : this.brandLogin();
    } else {
      (this.$storageData.profile.empTypeID == 8) ? this.principleBranchLogin() : this.getPrincipleRestaurants();
      this.getPrincipleCountry();
    }
  },

  middleware: "authentication",
};
</script>
<style scoped>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>


